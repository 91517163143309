import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import GroupButton from '../group-button/group-button';
import PrimaryContactCheckBox from '../primary-contact-checkbox/primary-contact-checkbox';
import '../admin-table/admin-table.css';
import './group-table.css';
import config from '../../config';

export default class GroupTable extends Component {
  state = {
    newSeats: undefined,
    totalSeats: undefined,
    customers: [],
    primaryContactExists: false,
    primaryContactItemId: -1,
    primaryContactIndex: -1,
    stripePlanId: 'None',
    group: [],
    memberHeaders: [
      'First Name',
      'Last Name',
      'Email Address',
      'Company',
      'Agency Type',
      'Created Date',
      'Primary Contact',
    ],
    groupHeaders: [
      'Company Name',
      'Subscription',
      'Current Seats',
      'New Seats',
      'Total Seats',
    ],
    subscriptionPlans: [
      {
        id: 1,
        planName: 'Corporate Monthly',
        selected: false,
        stripeId: 'plan_F9QQJ8lzvMTEDp',
      },
      {
        id: 2,
        planName: 'Corporate Yearly',
        selected: false,
        stripeId: 'plan_F9QO7C5imSPOr3',
      },
      {
        id: 3,
        planName: 'Law Enforcement Monthly',
        selected: false,
        stripeId: 'plan_FAFHM6gHDYV1CW',
      },
      {
        id: 4,
        planName: 'Law Enforcement Yearly',
        selected: false,
        stripeId: 'plan_FAFIsS00UYSNPS',
      },
    ],
  };

  async componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    let resp;

    try {
      resp = await axios.get(
        `${config.apiGateway.URL}/users?filterKey=itemId&filterValue=${id}`,
        { headers: { Authorization: token } }
      );
      if (resp.data[0].primaryContact === true) {
        this.setState({
          group: resp.data,
          primaryContactItemId: resp.data[0].primaryContactItemId,
          primaryContactExists: resp.data[0].primaryContact,
          stripePlanId: resp.data[0].stripePlanId,
        });
      } else {
        this.setState({
          group: resp.data,
        });
      }
    } catch {
      console.log(resp);
    }

    try {
      resp = await axios.get(
        `${
          config.apiGateway.URL
        }/users?filterKey=companyItemId&filterValue=${id}`,
        { headers: { Authorization: token } }
      );

      this.setState({
        customers: resp.data,
      });
      const { customers, group } = this.state;
      const totalSeats = customers.length;
      const newSeats = totalSeats - group[0].seats;

      this.setState({
        newSeats,
        totalSeats,
      });
    } catch {
      console.log(resp);
    }
  }

  addPlanToCustomer = (index, stripeId) => {
    const { group } = this.state;
    const updateCustomer = group;

    updateCustomer[index].stripePlanId = stripeId;

    this.setState({
      group: updateCustomer,
    });
  };

  updatePrimaryContact = (index, itemId) => {
    const { customers, group, primaryContactItemId } = this.state;
    const updateCustomer = customers;
    const updateGroup = group;

    if (primaryContactItemId === itemId) {
      updateCustomer[index].primaryContact = false;
      updateGroup[0].primaryContact = false;
      updateGroup[0].primaryContactItemId = -1;

      this.setState({
        primaryContactItemId: -1,
        primaryContactIndex: -1,
        customers: updateCustomer,
        group: updateGroup,
      });
    } else {
      updateCustomer[index].primaryContact = true;
      updateGroup[0].primaryContact = true;
      updateGroup[0].primaryContactItemId = updateCustomer[index].itemId;

      this.setState({
        customers: updateCustomer,
        primaryContactItemId: updateCustomer[index].itemId,
        primaryContactIndex: index,
      });
    }
  };

  renderGroupHeaderRow = () => {
    const { groupHeaders } = this.state;

    return (
      <React.Fragment>
        <div className="gridHeader">{groupHeaders[0]}</div>
        <div className="gridHeader">{groupHeaders[1]}</div>
        <div className="gridHeader">{groupHeaders[2]}</div>
        <div className="gridHeader">{groupHeaders[3]}</div>
        <div className="gridHeader">{groupHeaders[4]}</div>
        <div className="gridHeader" />
        <div className="gridHeader" />
        <div className="gridHeader" />
      </React.Fragment>
    );
  };

  renderMemberHeaderRow = () => {
    const { memberHeaders } = this.state;

    return (
      <React.Fragment>
        <div className="gridHeader">{memberHeaders[0]}</div>
        <div className="gridHeader">{memberHeaders[1]}</div>
        <div className="gridHeader">{memberHeaders[2]}</div>
        <div className="gridHeader">{memberHeaders[3]}</div>
        <div className="gridHeader">{memberHeaders[4]}</div>
        <div className="gridHeader">{memberHeaders[5]}</div>
        <div className="gridHeader">{memberHeaders[6]}</div>
        <div className="gridHeader" />
      </React.Fragment>
    );
  };

  stripePlanIdLookUp = stripePlanId => {
    const { subscriptionPlans } = this.state;
    let i = 0;
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < subscriptionPlans.length; ++i) {
      if (subscriptionPlans[i].stripeId === stripePlanId) {
        return subscriptionPlans[i].planName;
      }
    }
  };

  renderGroupRow = _row => {
    const { customers, stripePlanId } = this.state;
    const totalSeats = customers.length;
    const newSeats = totalSeats - _row.seats;
    return (
      <React.Fragment>
        <div className="custName">{_row.companyName}</div>
        <div className="Subscription">
          {this.stripePlanIdLookUp(stripePlanId)}
        </div>
        <div className="seats">{_row.seats}</div>
        <div className="seats">{newSeats}</div>
        <div className="seats">{totalSeats}</div>
        <div />
        <div />
        <div />
      </React.Fragment>
    );
  };

  renderMemberRow = (_row, rowIndex) => {
    const { primaryContactExists, primaryContactItemId } = this.state;
    let rowDisabled = false;
    let checked = '';

    if (primaryContactItemId === -1) {
      rowDisabled = false;
      checked = '';
    } else if (_row.itemId !== primaryContactItemId) {
      rowDisabled = true;
      checked = false;
    } else {
      checked = true;
    }

    if (primaryContactExists === true) {
      rowDisabled = true;
    }

    return (
      <React.Fragment>
        <div className="">{_row.firstName}</div>
        <div className="">{_row.lastName}</div>
        <div className="">{_row.emailAddress}</div>
        <div className="">{_row.companyName}</div>
        <div className="">{_row.agencyType}</div>
        <div className="">{_row.createdDate}</div>
        <div className="Primary Contact">
          <PrimaryContactCheckBox
            checkedStatus={checked}
            rowIndex={rowIndex}
            rowStatus={rowDisabled}
            member={_row}
            onChange={(i, j) => this.updatePrimaryContact(i, j)}
          />
        </div>
        <div> </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      customers,
      group,
      totalSeats,
      newSeats,
      primaryContactIndex,
    } = this.state;
    const groupHeader = this.renderGroupHeaderRow();
    const groupRows = group.map(_row => this.renderGroupRow(_row));
    const memberHeader = this.renderMemberHeaderRow();
    const memberRows = customers.map((_row, rowIndex) =>
      this.renderMemberRow(_row, rowIndex)
    );

    return (
      <div className="flexContainer">
        <div className="tableDirection">Select Membership Level</div>
        <div className="blankSpace" />
        <div className="memberGridWrapper">
          {groupHeader}
          {groupRows}
          {memberHeader}
          {memberRows}
        </div>
        <div className="submitBtn groups">
          <GroupButton
            rowIndex={1}
            customers={customers}
            group={group}
            totalSeats={totalSeats}
            newSeats={newSeats}
            primaryContactIndex={primaryContactIndex}
          />
        </div>
      </div>
    );
  }
}

GroupTable.propTypes = {
  match: PropTypes.object,
};
