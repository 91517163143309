import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import GroupTable from '../group-table/group-table';
import '../admin-table/admin-table.css';
import config from '../../config';

export default class Button extends Component {
  state = {
    isDisabled: false,
    groupHasSubscription: false,
  };

  componentDidMount() {
    this.subscriptionStatus();
  }

  componentDidUpdate() {
    this.subscriptionStatus();
  }

  addCustomerToStripe = async (rowIndex, customers, removeCustomers) => {
    let customerObject = {};
    customerObject = customers[rowIndex];

    if (
      typeof customerObject.stripePlanId !== 'undefined' &&
      customerObject.stripePlanId !== 'None'
    ) {
      const stringObj = JSON.stringify(customerObject);

      try {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        await axios({
          method: 'post',
          url: `${config.apiGateway.URL}/subscriptions/${
            customerObject.itemId
          }`,
          data: {
            paramName: 'subscriptionStatus',
            paramValue: `${customerObject.stripePlanId}`,
            companyItemId: `${customerObject.companyItemId}`,
            user: `${stringObj}`,
            totalSeats: 1,
          },
          headers: { Authorization: token },
        });
        removeCustomers(rowIndex);
      } catch (err) {
        console.log('Cannot create new subscription');
        console.log(err);
      }
    } else {
      console.log('please assign stripe id');
    }
  };

  subscriptionStatus = async () => {
    const { rowIndex, customers } = this.props;
    const { groupHasSubscription } = this.state;

    let customerObject = {};
    customerObject = customers[rowIndex];

    let resp;

    if (!groupHasSubscription) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        resp = await axios({
          method: 'get',
          url: `${config.apiGateway.URL}/users/${customerObject.companyItemId}`,
          headers: { Authorization: token },
        });

        if (
          resp.data.subscriptionStatus === 'active' &&
          !groupHasSubscription
        ) {
          this.setState({
            groupHasSubscription: true,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  renderButton = (rowIndex, customers, removeCustomers) => {
    const { isDisabled, groupHasSubscription } = this.state;

    let customerObject = {};
    customerObject = customers[rowIndex];

    if (!isDisabled && !groupHasSubscription) {
      return (
        <React.Fragment>
          {!isDisabled ? (
            <button
              className="rowSubmitBtn"
              type="submit"
              onClick={() =>
                this.addCustomerToStripe(rowIndex, customers, removeCustomers)
              }
            >
              Submit
            </button>
          ) : (
            ' '
          )}
        </React.Fragment>
      );
    }
    if (!isDisabled && groupHasSubscription) {
      return (
        <React.Fragment>
          {!isDisabled ? (
            <Link
              className="rowSubmitBtn"
              to={`/Group/${customerObject.companyItemId}`}
            >
              Submit
            </Link>
          ) : (
            ' '
          )}
          <Route exact path="/Group/:id" component={GroupTable} />
        </React.Fragment>
      );
    }
  };

  render() {
    const { rowIndex, customers, removeCustomers } = this.props;
    const button = this.renderButton(rowIndex, customers, removeCustomers);

    return <div>{button}</div>;
  }
}

Button.propTypes = {
  rowIndex: PropTypes.number,
  customers: PropTypes.array,
  removeCustomers: PropTypes.func,
};
