import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../admin-table/admin-table.css';

export default class PrimaryContactCheckBox extends Component {
  selectItem = e => {
    const { rowIndex, member, onChange } = this.props;
    console.log(e.target);
    onChange(rowIndex, member.itemId);
  };

  render() {
    const { rowStatus, checkedStatus } = this.props;
    return (
      <input
        checked={checkedStatus}
        type="checkbox"
        name="primaryContact"
        disabled={rowStatus}
        onChange={e => this.selectItem(e)}
      />
    );
  }
}

PrimaryContactCheckBox.propTypes = {
  member: PropTypes.object,
  onChange: PropTypes.func,
  rowIndex: PropTypes.number,
  rowStatus: PropTypes.bool,
  checkedStatus: PropTypes.bool,
};
