// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "crimedexsubscription-20190527151646-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://crimedexsubscription-20190527151646-hostingbucket-production.s3-website-us-west-2.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-west-2:927b2eaa-c7c5-45b7-9f00-ca8fd933b4bb",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_6EYSVBdjI",
    "aws_user_pools_web_client_id": "3vtc529rgdb90fcudqlgkh4dja",
    "oauth": {}
};


export default awsmobile;
