const development = {
  apiGateway: {
    URL: 'https://lbs08nz8r9.execute-api.us-west-2.amazonaws.com/staging',
  },
  stripePlans: [
    {
      id: 1,
      planName: 'Corporate Monthly',
      selected: false,
      stripeId: 'plan_FSBgk6eRamzd2q',
    },
    {
      id: 2,
      planName: 'Corporate Yearly',
      selected: false,
      stripeId: 'plan_FSBheYaSlM6HbP',
    },
    {
      id: 3,
      planName: 'Law Enforcement Monthly',
      selected: false,
      stripeId: 'plan_FSBe1vqo6Lbo79',
    },
    {
      id: 4,
      planName: 'Law Enforcement Yearly',
      selected: false,
      stripeId: 'plan_FSBef52Y76VFGk',
    },
  ],
};

const staging = {
  apiGateway: {
    URL: 'https://lbs08nz8r9.execute-api.us-west-2.amazonaws.com/staging',
  },
  stripePlans: [
    {
      id: 1,
      planName: 'Corporate Monthly',
      selected: false,
      stripeId: 'plan_FSBgk6eRamzd2q',
    },
    {
      id: 2,
      planName: 'Corporate Yearly',
      selected: false,
      stripeId: 'plan_FSBheYaSlM6HbP',
    },
    {
      id: 3,
      planName: 'Law Enforcement Monthly',
      selected: false,
      stripeId: 'plan_FSBe1vqo6Lbo79',
    },
    {
      id: 4,
      planName: 'Law Enforcement Yearly',
      selected: false,
      stripeId: 'plan_FSBef52Y76VFGk',
    },
  ],
};

const production = {
  apiGateway: {
    URL: 'https://d867hbc5k7.execute-api.us-west-2.amazonaws.com/production',
  },
  stripePlans: [
    {
      id: 1,
      planName: 'Corporate Monthly',
      selected: false,
      stripeId: 'plan_FU53rlY778nPT3',
    },
    {
      id: 2,
      planName: 'Corporate Yearly',
      selected: false,
      stripeId: 'plan_FU8M769aWdmMBJ',
    },
    {
      id: 3,
      planName: 'Law Enforcement Monthly',
      selected: false,
      stripeId: 'plan_FU56sCxeqi2v2j',
    },
    {
      id: 4,
      planName: 'Law Enforcement Yearly',
      selected: false,
      stripeId: 'plan_FU8Lr2uQFFQykQ',
    },
  ],
};

// Default to development if not set
let config;

if (process.env.REACT_APP_STAGE === 'production') {
  config = production;
} else if (process.env.REACT_APP_STAGE === 'staging') {
  config = staging;
} else {
  config = development;
}

export default {
  ...config,
};
