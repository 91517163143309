import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import SubscriptionDropdown from '../subscription-dropdown/subscription-dropdown';
import Button from '../button/button';
import './admin-table.css';
import config from '../../config';

export default class AdminTable extends Component {
  state = {
    customers: [],
    headers: [
      'First Name',
      'Last Name',
      'Email Address',
      'Company',
      'Agency Type',
      'Created Date',
      'Subscription',
    ],
    subscriptionPlans: config.stripePlans,
  };

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    axios
      .get(`${config.apiGateway.URL}/pendingUsers`, {
        headers: { Authorization: token },
      })
      .then(resp => {
        const items = resp.data.map(item => this.setStripePlanId(item));

        this.setState({
          customers: items,
        });
      })
      .catch(err => console.log(err.data));
  }

  setStripePlanId = item => {
    if (item.agencyType.startsWith('LAW_ENFORCEMENT')) {
      item.stripePlanId = config.stripePlans.find(
        plan => plan.planName === 'Law Enforcement Yearly'
      ).stripeId;
    }

    if (item.agencyType.startsWith('CORPORATE')) {
      item.stripePlanId = config.stripePlans.find(
        plan => plan.planName === 'Corporate Yearly'
      ).stripeId;
    }

    return item;
  };

  removeCustomers = index => {
    const { customers } = this.state;
    const updatedCustomers = [...customers];

    updatedCustomers.splice(index, 1);

    console.log(updatedCustomers);

    this.setState({
      customers: updatedCustomers,
    });
  };

  addPlanToCustomer = (index, stripeId, planName) => {
    const { customers } = this.state;
    const updateCustomer = [...customers];

    console.log(updateCustomer[index]);

    updateCustomer[index].stripePlanId = stripeId;
    updateCustomer[index].stripePlanName = planName;

    this.setState({
      customers: updateCustomer,
    });
  };

  renderHeaderRow = () => {
    const { headers } = this.state;

    return (
      <React.Fragment>
        <div className="gridHeader">{headers[0]}</div>
        <div className="gridHeader">{headers[1]}</div>
        <div className="gridHeader">{headers[2]}</div>
        <div className="gridHeader">{headers[3]}</div>
        <div className="gridHeader">{headers[4]}</div>
        <div className="gridHeader">{headers[5]}</div>
        <div className="gridHeader">{headers[6]}</div>
        <div className="gridHeader" />
      </React.Fragment>
    );
  };

  renderTableRow = (_row, rowIndex) => {
    const { subscriptionPlans } = this.state;
    const { customers } = this.state;

    return (
      <React.Fragment key={rowIndex}>
        <div className="">{_row.firstName}</div>
        <div className="">{_row.lastName}</div>
        <div className="">{_row.emailAddress}</div>
        <div className="">{_row.companyName}</div>
        <div className="">{_row.agencyType}</div>
        <div className="">{_row.createdDate}</div>
        <div className="">
          <SubscriptionDropdown
            subPlans={subscriptionPlans}
            rowIndex={rowIndex}
            stripePlanId={_row.stripePlanId}
            onChange={(index, stripeId, planName) =>
              this.addPlanToCustomer(index, stripeId, planName)
            }
          />
        </div>
        <div className="submitBtn">
          <Button
            rowIndex={rowIndex}
            customers={customers}
            removeCustomers={index => this.removeCustomers(index)}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { customers } = this.state;
    const header = this.renderHeaderRow();
    const tableRows = customers.map((_row, rowIndex) =>
      this.renderTableRow(_row, rowIndex)
    );

    return (
      <div className="flexContainer">
        <div className="tableDirection">Select Membership Level</div>
        <div className="blankSpace" />
        <div className="wrapper">
          {header}
          {tableRows}
        </div>
      </div>
    );
  }
}
