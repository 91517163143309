import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../admin-table/admin-table.css';
import { Auth } from 'aws-amplify';
import config from '../../config';

class Button extends Component {
  state = {
    isDisabled: false,
  };

  async addCustomerToStripe(rowIndex, customers) {
    const {
      group,
      newSeats,
      totalSeats,
      primaryContactIndex,
      history,
    } = this.props;
    let stringObj = {};
    let customerObj = [];

    if (group[0].primaryContact === true && newSeats > 0) {
      if (primaryContactIndex < 0) {
        stringObj = JSON.stringify(group[0]);
        customerObj = JSON.stringify(customers);
      } else {
        stringObj = JSON.stringify(group[primaryContactIndex]);
        customerObj = JSON.stringify(customers);
      }

      this.setState({
        isDisabled: true,
      });

      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      await axios({
        method: 'post',
        url: `${config.apiGateway.URL}/updateSubscriptions/`,
        data: {
          paramName: 'subscriptionStatus',
          paramValue: `${group[0].stripePlanId}`,
          groupItemId: `${group[0].itemId}`,
          groupItem: `${stringObj}`,
          totalSeats: `${totalSeats}`,
          customers: `${customerObj}`,
        },
        headers: { Authorization: token },
      });

      history.push('/');
    }
  }

  render() {
    const { rowIndex, customers, newSeats } = this.props;
    const { isDisabled } = this.state;
    let btn;
    if (newSeats === 0) {
      btn = (
        <button className="rowSubmitBtnDisabled" type="submit" disabled>
          Submit
        </button>
      );
    } else {
      btn = !isDisabled ? (
        <button
          className="rowSubmitBtn"
          type="submit"
          onClick={() => this.addCustomerToStripe(rowIndex, customers)}
        >
          Submit
        </button>
      ) : (
        ' '
      );
    }

    return <React.Fragment>{btn}</React.Fragment>;
  }
}

Button.propTypes = {
  rowIndex: PropTypes.number,
  primaryContactIndex: PropTypes.number,
  totalSeats: PropTypes.number,
  newSeats: PropTypes.number,
  customers: PropTypes.array,
  group: PropTypes.array,
  history: PropTypes.element,
};

export default withRouter(Button);
