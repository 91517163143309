import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './subscription-dropdown.css';

export default class SubscriptionDropdown extends Component {
  selectItem = e => {
    const { rowIndex, onChange } = this.props;
    const stripeId = e.target.options[e.target.selectedIndex].value;
    const planName = e.target.options[e.target.selectedIndex].text;
    onChange(rowIndex, stripeId, planName);
  };

  render() {
    const { rowIndex, subPlans, stripePlanId } = this.props;
    return (
      <select
        className="select-css"
        value={stripePlanId}
        id="dd-plan-item"
        onChange={e => this.selectItem(e)}
      >
        <option key={rowIndex} name="None" value="None">
          Subscription Level
        </option>
        {subPlans.map(item => (
          <option
            className="dd-list-item"
            key={item.id}
            value={item.stripeId}
            name={item.planName}
          >
            {item.planName}
          </option>
        ))}
      </select>
    );
  }
}
SubscriptionDropdown.propTypes = {
  onChange: PropTypes.func,
  rowIndex: PropTypes.number,
  subPlans: PropTypes.array,
  stripePlanId: PropTypes.string,
};
