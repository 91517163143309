import Amplify from 'aws-amplify';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { withAuthenticator } from 'aws-amplify-react';
import awsmobile from '../../aws-exports';
import logo from '../../assets/logo.svg';
import AdminTable from '../admin-table/admin-table';
import GroupTable from '../group-table/group-table';
import '../../index.css';

Amplify.configure(awsmobile);

class App extends Component {
  render() {
    const today = new Date();
    const currentYear = today.getFullYear();

    return (
      <Router>
        <div className="app">
          <header className="header">
            <img src={logo} className="logo" alt="logo" />
            <section className="nav">
              <ul>
                <li>
                  <a href="http://www.crimedex.com">Home</a>
                </li>
                <li className="active">
                  <a href="/">Admin</a>
                </li>
              </ul>
            </section>
          </header>
          <section className="main">
            <Route exact path="/" component={AdminTable} />
            <Route exact path="/Group/:id" component={GroupTable} />
          </section>
          <footer>
            <div className="copywrite">
              <span> &#169; </span>
              <span>CrimeDex </span>
              {currentYear}
            </div>
          </footer>
        </div>
      </Router>
    );
  }
}

export default withAuthenticator(App, true);
